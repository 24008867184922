/*******************************************************************************
 * EventTable - A table for managing event configuration in the 4-H photo
 * upload tool.
 ******************************************************************************/

import * as React from 'react';
import { Button, Container, Table, TableBody, TableContainer, TableHead,
  TableRow, IconButton, Paper } from '@mui/material';
import {
  EditableTextField,
  EditableBooleanField,
  EditableOverflowTextField
} from './EditableField';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectEventImageForm, EventImageList } from './UploadDialog';
import { StyledTableRow, StyledTableCell } from './SettingRow';

function EventRow({
    eventObject, // JSON object describing the event
    onChange, // callback to modify the event data
    onStore, // callback to push modified changes to server
    onRemove, // callback to delete the indicated item from the server
    noDistrictComp = false, // control to disable district competition switch
}) {
  const [uploadFormOpen, setUploadFormOpen] = React.useState(false);
  const [editActive, setEditActive] = React.useState(false);

    const doChange = (key, value) => {
        let data = eventObject;
        data[key] = value;
        onChange(data);
    }
  
    const toggleEdit = () => {
      setEditActive(!editActive);
      if (editActive) {
        onStore(eventObject.event_id);
      }
    }

    const afterUploadComplete = () => {
      setUploadFormOpen(false);
    }

    const selectEventImage = (imageId) => {
      doChange("image_id", imageId);
      setUploadFormOpen(false);
    }


    return(
      <>
        <SelectEventImageForm
          open={uploadFormOpen}
          onClose={() => {setUploadFormOpen(false)}}
          onUploadComplete={afterUploadComplete}
          title="Select Event Image"
        >
          <EventImageList onSelect={selectEventImage}/>
        </SelectEventImageForm>
        <StyledTableRow
        key={eventObject.event_id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <StyledTableCell component="tr" scope="row">
            <EditableTextField
            value={eventObject.name}
            inEditMode={editActive}
            setValue={(value) => {doChange("name", value)}}
            />
        </StyledTableCell>
        <StyledTableCell component="tr" scope="row">
            <EditableTextField
            value={eventObject.slug}
            inEditMode={editActive}
            setValue={(value) => {doChange("slug", value)}}
            />
        </StyledTableCell>
        <StyledTableCell component="tr" scope="row" align="center">
            {!noDistrictComp &&
            <EditableBooleanField
            value={eventObject.uses_district_competition}
            inEditMode={editActive}
            setValue={(value) => {doChange("uses_district_competition", value)}}
            />}
            <EditableBooleanField
            value={eventObject.uses_scavenger_hunt}
            inEditMode={editActive}
            setValue={(value) => {doChange("uses_scavenger_hunt", value)}}
            />
        </StyledTableCell>
        <StyledTableCell component="tr" scope="row" align="left">
          <IconButton
            variant="outlined"
            color="secondary"
            disabled={!editActive}
            onClick={() => {setUploadFormOpen(true)}}
          >
            <img
              // TODO: Update with a variable for the URL
              src={`https://albums.idaho4h.org/api/Photo::getArchive?photoIDs=${eventObject.image_id}&kind=THUMB`}
              alt="" width="80"
            />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="right">
            <Button
            variant="outlined"
            color="secondary"
            onClick={toggleEdit}
            >
            {editActive? "Save" : "Edit"}
            </Button>
        </StyledTableCell>
        <StyledTableCell align="right">
            <IconButton
            variant="outlined"
            color="error"
            onClick={() => {onRemove(eventObject.event_id)}}
            >
            <DeleteIcon/>
            </IconButton>
        </StyledTableCell>
        </StyledTableRow>
      </>
    )
}

export function BestBetterEventTable({
    data, // list of objects corresponding to rows
    onAdd, // callback when add button used
    onRemove, // callback when delete button used
    onChange, // callback to change values
    onStore, // callback to push modified changes to server
    noDistrictComp = false, // control to disable district competition switch
  }) {
  
     return (
      <Container maxWidth={false}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Event Name</StyledTableCell>
                <StyledTableCell>Slug</StyledTableCell>
                <StyledTableCell align="center">
                    {!noDistrictComp && <>District Competition<br/></>}
                    Scavenger Hunt
                </StyledTableCell>
                <StyledTableCell>Landing Image</StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
                <StyledTableCell align="right">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((entry) => (
                <EventRow
                  eventObject={entry}
                  onChange={onChange}
                  onStore={onStore}
                  onRemove={onRemove}
                  noDistrictComp={noDistrictComp}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button variant="contained" onClick={onAdd}>Add</Button>
       </Container>
     );
  }