/*******************************************************************************
 * SettingRow - A generic table row, allowing editing or deletion of data.
 ******************************************************************************/

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { EditableTextField, EditableBooleanField } from './EditableField';


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


/* EditiableRow is for n-row tables whose rows represent names. */
export function EditableRow({
  values, // tuple of primary and short text displayed in the row
  onChange, // callback to modify the values
  onRemove, // callback to remove the value from the table
}) {
  const [key, setKey] = React.useState("");
  const [presentVal, setPresentVal] = React.useState("");
  const [editActive, setEditActive] = React.useState(false);

  React.useEffect(()=>{
    // Load Requisites when page Completes
    setPresentVal(values);
    setKey(values);
  },[]);

  const toggleEdit = () => {
    setEditActive(!editActive);
    if (editActive) {
      // Turning off edit (saving) -- update the parent data
      //       old    new
      onChange(values, presentVal);
    }
  }

  const doRemove = () => {
    onRemove(presentVal);
  }

  return (
    <StyledTableRow
      key={key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <StyledTableCell component="tr" scope="row">
        <EditableTextField
          value={presentVal}
          inEditMode={editActive}
          setValue={setPresentVal}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <Button variant="outlined" color="secondary" onClick={toggleEdit}>
          {editActive? "Save" : "Edit"}
        </Button>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Button variant="outlined" color="secondary" onClick={doRemove}>
          Delete
        </Button>
      </StyledTableCell>
    </StyledTableRow>
  )
}

/* EditiableLine is for named rows in a table where purpose is row-unique. */
export function EditableLine({
  rowKey, // keying string
  dataName, // name of the data value being controlled by the row
  value, // text displayed (and modifyable) in the row
  treatAsSecret, // control to specify whether the value should be hidden
  onChange, // callback to modify the value
}) {
  const [presentVal, setPresentVal] = React.useState(value);
  const [editActive, setEditActive] = React.useState(false);

  const toggleEdit = () => {
    setEditActive(!editActive);
    if (editActive) {
      // Turning off edit (saving) -- update the parent data
      //       old    new
      onChange(rowKey, presentVal);
    }
  }

  return (
    <StyledTableRow
      key={rowKey}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <StyledTableCell component="th" scope="row">
        <th>{dataName}</th>
      </StyledTableCell>
      <StyledTableCell component="tr" scope="row">
        {(typeof presentVal === 'string' || typeof presentVal === 'number') &&
          <EditableTextField
            value={presentVal}
            inEditMode={editActive}
            secret={treatAsSecret}
            setValue={setPresentVal}
          />
        }
        {(typeof presentVal === 'boolean') &&
          <EditableBooleanField
            value={presentVal}
            inEditMode={editActive}
            setValue={setPresentVal}
          />
        }
      </StyledTableCell>
      <StyledTableCell align="right">
        <Button variant="outlined" color="secondary" onClick={toggleEdit}>
          {editActive? "Save" : "Edit"}
        </Button>
      </StyledTableCell>
    </StyledTableRow>
  )
}
