/*******************************************************************************
 * DistrictEvents.js - View for District Event Names Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterEventTable } from "./fields/EventTable";
import { a11yProps, TabPanel } from "./fields/SettingsTable";
import { Typography } from '@mui/material';

export default function DistrictEventsView() {
  const [selectionIdx, setSelectedIdx] = React.useState(0);
  const [districts, setDistricts] = React.useState([]);
  const [districtEvents, setDistrictEvents] = React.useState([]);
  const [selectedDistrictEvents, setSelectedDistrictEvents] = React.useState([]);

  const doUpdateDistrictEvent = (event_data) => {
    // Be sure to clone the original object to trigger React's rerendering
    let events = structuredClone(selectedDistrictEvents);
    // Iterate Events to Identify the Specific One by its ID
    Array.from(events).forEach((event, idx, _) => {
      if (event.event_id === event_data.event_id) {
        // Update the Specific Event
        events[idx] = event_data;
      }
    })
    setSelectedDistrictEvents(events);
  }

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getDistrictNames();
  },[]);

  const getDistrictNames = () => {
    api_client.get("districts/list", {
      headers: {'Accept': 'application/json'},
    }).then(res => res.data).then(jsonData => {
      // Set the District Names
      setDistricts(jsonData);
      if (selectedDistrictEvents.length === 0) {
        getDistrictEventNames(jsonData[0]);
      }
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const getDistrictEventNames = (district=null) => {
    api_client.get(
      (!!district ? `events/district/?district_name=${district}` : "events/district/"),
      {
        headers: {'Accept': 'application/json'},
      }
    ).then(res => res.data).then(jsonData => {
      // Set the State Event Names
      if (!!district) {
        setSelectedDistrictEvents(jsonData);
      } else {
        setDistrictEvents(jsonData);
      }
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const addDistrictEvent = () => {
    api_client.put(`events/district/${districts[selectionIdx]}/`,
      null,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getDistrictEventNames(districts[selectionIdx])}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const removeDistrictEvent = (event_id) => {
    // Get the specific event data
    const payload = selectedDistrictEvents.filter(event => event.event_id === event_id)[0];
    api_client.delete("events/district/",
      {
        data: payload,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getDistrictEventNames(districts[selectionIdx])}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const storeDistrictEvent = (event_id) => {
    // Get the specific event data
    const payload = selectedDistrictEvents.filter(event => event.event_id === event_id)[0];
    api_client.post("events/district/",
      payload,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
      ).then(_ => {getDistrictEventNames(districts[selectionIdx])}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const handleTabChange = (event, newIndexValue) => {
    setSelectedIdx(newIndexValue);
    getDistrictEventNames(districts[newIndexValue]);
  };

 return (
  <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {districts.length === 0 &&
        <Typography >
          No Districts are configured. Please add a district in&nbsp;
          <a href="/admin/?view=District%20Names">District Names</a>.
        </Typography>
      }
      <Tabs
        value={selectionIdx}
        onChange={handleTabChange}
        aria-label="best-better-tabs"
      >
        {districts.map((district, index) => (
          <Tab label={district} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
    {districts.map((district, index) => (
      <TabPanel value={selectionIdx} index={index}>
        <BestBetterEventTable
          data={selectedDistrictEvents}
          onAdd={addDistrictEvent}
          onRemove={removeDistrictEvent}
          onChange={doUpdateDistrictEvent}
          onStore={storeDistrictEvent}
          noDistrictComp={true}
        />
      </TabPanel>
    ))}
  </Box>
 )
}